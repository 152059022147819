import axios from 'axios';
import { history } from '../history';

const instance = axios.create({
  // baseURL: 'https://api-backoffice-sandbox.ipague.com.br/',
  baseURL: 'https://api-backoffice.ipague.com.br',
  // baseURL: 'http://localhost:5008/api',
  withCredentials: true
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      history.push('/plataforma/login');
    }
    return Promise.reject(error.response);
  }
);

export default instance;
